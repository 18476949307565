<template>
  <ul class="nav nav-tabs" role="tablist">
    <li
      class="nav-item"
      :class="tab.tabClass"
      role="presentation"
      v-for="(tab, index) in tabs"
      :key="index"
    >
      <button
        :ref="setItemRef"
        class="nav-link"
        :id="`${tab.id}-tab`"
        data-bs-toggle="tab"
        :data-bs-target="`#${tab.id}`"
        type="button"
        role="tab"
        :aria-controls="tab.id"
        aria-selected="true"
      >
        {{ tab.label }}
      </button>
    </li>
  </ul>
  <div class="tab-content" id="myTabContent">
    <div
      v-for="(tab, index) in tabs"
      :key="index"
      class="tab-pane fade"
      :id="tab.id"
      role="tabpanel"
      :aria-labelledby="`${tab.id}-tab`"
    >
      <slot :name="tab.id"></slot>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import bootstrap from "bootstrap/dist/js/bootstrap.min.js";

export default {
  name: "TabsJs",
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    tabClass: {
      type: String,
      required: false,
      default: "tabClass",
    },
  },
  data() {
    return {
      tabRefs: [],
      activeTab: "",
    };
  },
  computed: {
    ...mapState({ activeTab_s: "activeTab" }),
  },
  mounted() {
    for (let tab of this.tabRefs) {
      tab.addEventListener("shown.bs.tab", this.changeTab);
    }
  },
  beforeUnmount() {
    for (let tab of this.tabRefs) {
      tab.removeEventListener("shown.bs.tab", this.changeTab);
    }
  },
  methods: {
    ...mapMutations({ changeTab_s: "changeTab" }),

    setItemRef(el) {
      if (el) {
        this.tabRefs.push(el);
      }
    },
    changeTab(event) {
      this.activeTab = event.target.getAttribute("aria-controls");
      this.changeTab_s(event.target.getAttribute("aria-controls"));
    },
  },
  watch: {
    activeTab_s(tabId) {
      if (this.activeTab !== tabId) {
        this.tabRefs.some((el) => {
          if (el.getAttribute("aria-controls") === tabId) {
            const tab = new bootstrap.Tab(el);
            tab.show();
            return true;
          }
        });
      }
    },
  },
};
</script>
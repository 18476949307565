<template>
  <!-- Modal -->

  <div
    class="modal fade show"
    style="display: block"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" :class="position == 'center' ? 'modal-dialog-centered' : ''">
      <div class="modal-content">
        <div class="modal-header" v-if="$slots.header">
          <h5 class="modal-title" id="exampleModalLabel">
            <slot name="header"></slot>
          </h5>
          <button
            type="button"
            class="btn-close"
            @click="closeModal"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div v-if="htmlContent" class="modal-body m-0 p-0" v-html="htmlContent"></div>
        <div class="modal-body text-center">
          <slot></slot>
        </div>
        <div class="modal-footer">
          <input
            type="submit"
            @click="checkoutModal"
            class="btn btn-primary"
            :value="checkoutBtn"
            v-if="displayCheckoutBtn"
          />

          <input
            type="submit"
            @click="submitModal"
            class="btn btn-primary"
            :value="submitBtn"
            v-if="submitBtn"
          />

          <input
            type="submit"
            @click="closeModal"
            class="btn btn-primary"
            :value="closeBtn"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="modal-backdrop fade show"></div>
</template>

<script>
export default {
  name: "ModalPopUp",
  props: {
    closeFn: {
      type: Function,
      required: true,
    },
    closeBtn: {
      type: String,
      default: "OK",
    },
    submitFn: {
      type: Function,
    },
    submitBtn: {
      type: String,
      default: "",
    },
    checkoutFn: {
      type: Function,
    },
    checkoutBtn: {
      type: String,
      default: "Checkout Now",
    },
    displayCheckoutBtn: {
      type: Boolean,
      default: false,
    },
    htmlContent: {
      default: "",
    },
    position: {
      default: "",
    }
  },
  methods: {
    closeModal() {
      this.closeFn();
    },
    submitModal() {
      this.submitFn();
    },
    checkoutModal() {
      this.checkoutFn();
    },
  },
};
</script>
<style scoped>
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #9f9f9f;
  border-color: #9f9f9f;
}

.buttons .btn:hover,
.btn[type="submit"]:hover,
#account-login .well .btn:hover {
  border: 2px solid #9f9f9f;
  background: #9f9f9f;
  color: #fff;
}
.buttons .btn,
.btn[type="submit"],
#account-login .well .btn {
  border: 2px solid #9f9f9f;
  border-radius: inherit;
  background: inherit;
  color: #797979;
  padding: 8px 25px;
}
.btn-primary:hover {
  color: #fff;
  background-color: #9f9f9f;
  border-color: #9f9f9f;
}
h4 {
  font-size: 1.2 rem;
}
thead {
  background: #efefef;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.modal-footer {
  justify-content: center !important;
}

.btn[type="submit"] {
  border-radius: 0px;
}
</style>
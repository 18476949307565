<template>
  <section class="recently_viewed" v-if="recentlyViewed.length">
    <div class="container px-0">
      <div class="recently-viewed my-5">
        <h5 class="mb-4 font-weight-bold">Recently Viewed</h5>
        <div class="row">
          <div
            class="col-md-6 col-lg-3 mb-4"
            v-for="(recentlyViewedProduct, index) in recentlyViewed"
            :key="index"
          >
            <div class="product-wrapper text-center">
              <div class="product-img px-4 py-3 position-relative">
                <add-to-wishlist
                  v-if="isLoggedIn"
                  :prod="recentlyViewedProduct.product"
                ></add-to-wishlist>
                <a :href="`/${recentlyViewedProduct.product.seo_url}`">
                  <img
                    v-if="recentlyViewedProduct.product.primary_image"
                    :src="
                      `${imagePath}` +
                      recentlyViewedProduct.product.primary_image.image
                    "
                    class="m-0"
                    :alt="recentlyViewedProduct.product.name"
                  />
                  <img
                    v-else
                    :src="
                      `${imagePath}` +
                      getDefaultImage(
                        recentlyViewedProduct.product.product_type_id
                      )
                    "
                    class="m-0"
                    :alt="recentlyViewedProduct.product.name"
                  />
                </a>
              </div>
              <div class="px-3 pb-3">
                <div class="product-details">
                  <div class="product-name mb-1">
                    <a
                      :href="`/${recentlyViewedProduct.product.seo_url}`"
                      >{{ recentlyViewedProduct.product.name }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { misc } from "@/mixins";
import { AddToWishlist } from "@/components";

export default {
  name: "RecentlyViewed",
  mixins: [misc],
  components: {
    AddToWishlist
  },
  data() {
    return {
      recentlyViewed: [],
    };
  },
  mounted() {
    this.fetchRecentlyViewed();
  },
  methods: {
    fetchRecentlyViewed() {
      if (this.isLoggedIn) {
        axios
          .post(
            `${process.env.VUE_APP_API_BASE}auth/fetchRecentlyView`,
            {
              slug: this.slug,
            },
            {
              headers: {
                "Content-type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.recentlyViewed = response.data.products;
          });
      } else {
        this.recentlyViewed = [];
      }
    },
  },
};
</script>
<style>
.recently_viewed,
.recently_viewed .container {
  padding-left: 0px;
  padding-right: 0px;
}
</style>
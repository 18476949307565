<template>
  <div class="deleteIcon d-inline-block">
    <div class="w-100 d-block d-md-none"></div>
    <Tooltips text="Delete">
      <button @click="showDeletePPWarn(itemId)" class="btn">
        <i class="fa-solid fa-trash-can" aria-hidden="true"></i>
      </button>
    </Tooltips>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import Tooltips from "./Tooltips.vue";
export default {
  name: "DeleteProductPlanner",
  components: {
    Tooltips,
  },
  props: {
    itemId: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    ...mapMutations(["showDeletePPWarn"]),
  },
};
</script>
<template>
  <div class="date-picker-container">
    <div class="input-wrapper input-group flex-nowrap" ref="triggerRef" @click="toggleDatePicker">
      <input v-model="selectedDate" class="form-control" readonly placeholder="Select date">
      <span class="input-group-text"><i class="fas fa-calendar"></i></span>
    </div>

    <div v-show="isOpen" ref="datePickerRef" class="date-picker-wrapper">
      <div ref="arrow" class="popover-arrow"></div>
      <v-date-picker
        :key="key"
        @update:modelValue="handleDateSelect"
        v-model="dateModel"
        :first-day-of-week="1"
        color="green"
        :masks="{ input: 'MM-DD-YYYY' }"
        trim-weeks
        :available-dates="availableDates"
        :min-date="minDate"
        :max-date="maxDate"
        :disabled-dates="disabledDates"
        @dayclick="
          (_, event) => {
            event.target.blur();
          }
        " 
      />
    </div>
  </div>
  
  <modal
    v-if="onDateChange.warnModal.show"
    :closeFn="rejectDateChange"
    closeBtn="cancel"
    :submitFn="acceptDateChange"
    submitBtn="change dates"
    :checkoutFn="checkoutNow"
    checkoutBtn="Checkout Now"
    displayCheckoutBtn=true
  >
    By changing dates, you will lose the products that are in your cart, are
    you sure to go ahead?
  </modal>
</template>

<script>
import 'v-calendar/dist/style.css';
import { DatePicker as VDatePicker } from 'v-calendar';
import { mapState, mapActions } from "vuex";
import Modal from "./Modal";
import { misc } from "@/mixins";
import { createPopper } from '@popperjs/core'
  
export default {
  name: "DeliveryDatePicker",
  components: { Modal, VDatePicker },
  emits: ["update:dateProp", "deliveryDateChanged"],
  mixins: [misc],
  props: {
    availableDates: {
      type: Array,
      default() {
        return [];
      },
    },
    minDate: {
      type: String,
      default: "",
    },
    maxDate: {
      type: String,
      default: "",
    },
    disabledDates: {
      type: Array,
      default() {
        return [];
      },
    },
    dateProp: {
      type: String,
      default: "",
    },
    pageKey: {
      type: String,
      default: "",
    },
    shoppingListId: {
      type: Number,
      default: 0,
    },
    holHolidayId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      key: 0,
      selectedDate: null,
      isOpen: false,
      popperInstance: null,
      onDateChange: {
        warnModal: {
          show: false,
        },
        oldDate: "",
        trigger: true,
      },
    };
  },
  computed: {
    ...mapState(["cartMeta"]),

    dateModel: {
      get: function () {
        return this.appendTimeForBrowser(this.dateProp);
      },
      set: function (n) {
        if (n) {
          if (typeof n.getMonth === "function") {
            n = this.formatToDb(n);
          }

          const oldVal = this.appendTimeForBrowser(this.dateModel);
          this.$emit("update:dateProp", n);
          if (this.pageKey == 'sblDeliveryDate') {
            if (this.cartMeta.count && this.cartMeta[this.pageKey] != null && this.cartMeta[this.pageKey][this.shoppingListId] != '' && this.cartMeta[this.pageKey][this.shoppingListId] != null) {
              if (this.onDateChange.trigger) {
                this.onDateChange.oldDate = oldVal;
                this.onDateChange.warnModal.show = true;
              }
              this.onDateChange.trigger = true;
            } else {
              this.processDateChange();
            }
          } else {
            if (this.pageKey == 'holDeliveryDate') {
              if (this.cartMeta.count && this.cartMeta[this.pageKey] != null && this.cartMeta[this.pageKey][this.holHolidayId] != '' && this.cartMeta[this.pageKey][this.holHolidayId] != null) {
                if (this.onDateChange.trigger) {
                  this.onDateChange.oldDate = oldVal;
                  this.onDateChange.warnModal.show = true;
                }
                this.onDateChange.trigger = true;
              } else {
                this.processDateChange();
              }
            } else {
              if (this.cartMeta.count && this.cartMeta[this.pageKey] != '' && this.cartMeta[this.pageKey] != null) {
                if (this.onDateChange.trigger) {
                  this.onDateChange.oldDate = oldVal;
                  this.onDateChange.warnModal.show = true;
                }
                this.onDateChange.trigger = true;
              } else {
                this.processDateChange();
              }
            }
          }
        } else {
          this.key++;
        }
      },
    },
  },
  methods: {
    ...mapActions(["clearCartApi","clearCartByProgram"]),

    hideDateModal() {
      this.onDateChange.warnModal.show = false;
    },
    handleClickOutside(event) {
      if (
        this.isOpen &&
        !this.$refs.datePickerRef.contains(event.target) &&
        !this.$refs.triggerRef.contains(event.target)
      ) {
        this.isOpen = false
      }
    },
    toggleDatePicker() {
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        this.$nextTick(() => {
          this.initializePopper()
        })
      }
    },
    handleDateSelect(date) {
      this.selectedDate = date ? this.formatDateWithPaddedZeros(date) : '';
      this.isOpen = false
    },
    initializePopper() {
      this.popperInstance = createPopper(
        this.$refs.triggerRef,
        this.$refs.datePickerRef,
        {
          placement: 'bottom-start',
          modifiers: [
            {
              name: "arrow",
              options: {
                element: this.$refs.arrow,
              },
            },
            {
              name: 'offset',
              options: {
                offset: [0, 5]
              }
            },
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['top-start']
              }
            }
          ]
        }
      )
    },
    rejectDateChange() {
      this.onDateChange.trigger = false;
      this.dateModel = this.onDateChange.oldDate;
      this.hideDateModal();
    },
    acceptDateChange() {
      if (this.pageKey == 'shpDeliveryDate') {
        localStorage.setItem('cartProgram','SHP');
      }
      if (this.pageKey == 'ddbDeliveryDate') {
        localStorage.setItem('cartProgram','DBL');
      }
      if (this.pageKey == 'holDeliveryDate') {
        localStorage.setItem('cartProgram','DBL2');
        localStorage.setItem('holHolidayId',this.holHolidayId);
      }
      if (this.pageKey == 'hgsDeliveryDate') {
        localStorage.setItem('cartProgram','HGS');
      }
      if (this.pageKey == 'mmtDeliveryDate') {
        localStorage.setItem('cartProgram','MMT');
      }
      if (this.pageKey == 'spsDeliveryDate') {
        localStorage.setItem('cartProgram','SPS');
      }
      if (this.pageKey == 'sblDeliveryDate') {
        localStorage.setItem('cartProgram','SBL');
        localStorage.setItem('shoppingListId',this.shoppingListId);
      }
      this.clearCartByProgram();
      this.processDateChange();
      this.hideDateModal();
    },
    processDateChange() {
      this.$emit("deliveryDateChanged", this.dateModel);
    },
    checkoutNow() {
      let types = this.getCurrentCartTypes;
      let list = this.getCurrentShoppingLists;
      let holHoliday = this.getCurrentHolHolidays;
      let query = [];
      if (types != '' && types != undefined && types != null) {
        query['type'] = types;
      }
      if (list != '' && list != undefined && list != null) {
        query['list'] = list;
      }
      if (holHoliday != '' && holHoliday != undefined && holHoliday != null) {
        query['hol_holiday'] = holHoliday;
      }


      const queryString = Object.keys(query).map(key => `${key}=${query[key]}`).join('&');
      window.location.href = "/checkout?" + queryString;
    },
  },
  mounted() {
    this.handleDateSelect(this.dateModel);
    document.addEventListener('click', this.handleClickOutside)
  },
  unmounted() {
    document.removeEventListener('click', this.handleClickOutside)
    if (this.popperInstance) {
      this.popperInstance.destroy()
    }
  }
};
</script>
<style scoped>
.form-control {
  border-width: 1px;
}
.date-picker-container {
  position: relative;
}

.input-wrapper {
  position: relative;
  cursor: pointer;
}

.input-wrapper input {
  padding-right: 30px;
  cursor: pointer;
}

.input-wrapper i {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.date-picker-wrapper {
  z-index: 1000;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.375rem;
}

.form-input-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px;
  max-width: 300px;
  position: relative;
}

.form-input {
  flex: 1;
  border: none;
  outline: none;
  padding: 4px;
}

.popover-arrow {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #fff;
  transform: translateX(-50%) rotate(45deg) !important;
}

[data-popper-placement^="top"] > .popover-arrow {
  bottom: -5px;
  border-right: 1px solid #cbd5e1;
  border-bottom: 1px solid #cbd5e1;
  border-top: 0px solid #fff;
  background: #fff !important;
  z-index: 999999;
  transform: translateX(20px) rotate(45deg) !important;
}

[data-popper-placement^="bottom"] > .popover-arrow {
  top: -5px;
  border-left: 1px solid #cbd5e1;
  border-top: 1px solid #cbd5e1;
  border-bottom: 0px solid #fff;
  background: #fff !important;
  z-index: 999999;
  transform: translateX(20px) rotate(45deg) !important;
}
</style>
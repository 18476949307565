<template>
    <div id="install-pwa" class="container text-center text-md-end">
        <Popper v-if="show && isLoggedIn && !isOpera() && $route.name !== 'Carts' && $route.name !== 'Checkout'" :arrow="true" :hover="true" content="Use the install button to download our web app to install a shortcut to your phone or computer and to enable notifications which will allow you to receive real-time updates, news, and exclusive promotions!" style="margin: 0px; border-right-width: 0px;">
            <div class=" pb-2 py-lg-2">
                <a @click="installPWA" class="btn btn-theme w-100 w-md-auto">Install APP</a>
            </div>
        </Popper>
        <div v-else class="py-1 py-lg-4"></div>
    </div>
</template>
<script>
    import { pwa } from "@/mixins";
    import { mapGetters } from "vuex";
    import Popper from "vue3-popper";
    export default {
        name: "InstallPWA",
        mixins: [pwa],
        components: {
            Popper,
        },
        data() {
            return {
                show: false,
            }
        },
        computed: {
            ...mapGetters(["isLoggedIn"]),
        },
        beforeMount() {
            window.addEventListener("beforeinstallprompt", (e) => {
                e.preventDefault();
                this.installEvent = e;
                this.show = true;
            });
        },
        methods: {
            dismissPWAPrompt() {
                this.show = false;
            },
        },
    };
</script>
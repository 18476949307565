<template>
  <div class="col-lg-3 mb-4 col-6 product-grid">
    <div class="product-wrapper text-center">
      <div class="product-img px-4 py-3 position-relative">
        <add-to-wishlist v-if="isLoggedIn" :prod="product" class="fr-wish"></add-to-wishlist>
        <template v-if="deliveryDate != ''">
          <router-link :to="`/${product.seo_url}?date=${deliveryDate}`">
            <div class="ribbon" v-if="product.highlight_id">
              <span
                :style="`border-color: ${product.highlight_hex_code}; background: ${product.highlight_hex_code}; background: linear-gradient(${ product.highlight_hex_code } 0%, ${ product.highlight_hex_code } 100%);`">{{
                product.highlight_name }}</span>
            </div>
            <img v-if="product.image" :src="`${imagePath}` + product.image" class="" :alt="product.name" />
            <img v-else :src="`${imagePath}` + getDefaultImage(product.product_type_id)" class="" :alt="product.name" />
          </router-link>
        </template>
        <template v-else>
          <router-link :to="`/${product.seo_url}`">
            <div class="ribbon" v-if="product.highlight_id">
              <span
                :style="`border-color: ${product.highlight_hex_code}; background: ${product.highlight_hex_code}; background: linear-gradient(${ product.highlight_hex_code } 0%, ${ product.highlight_hex_code } 100%);`">{{
                product.highlight_name }}</span>
            </div>
            <img v-if="product.image" :src="`${imagePath}` + product.image" class="" :alt="product.name" />
            <img v-else :src="`${imagePath}` + getDefaultImage(product.product_type_id)" class="" :alt="product.name" />
          </router-link>
        </template>
      </div>
      <div class="px-3 pb-3">
        <div class="product-details">
          <div class="product-name mb-1">
            <span class="d-inline-block highlight-description" v-if="product.highlight_description">
              <Popper :arrow="true" :hover="true" :content="product.highlight_description" style="margin:0; border: none;">
                <i class="fa-solid fa-circle-info info-icon"></i>
              </Popper>
            </span>
            <template v-if="deliveryDate != ''">
              <router-link :to="`/${product.seo_url}?date=${deliveryDate}`">{{ product.name }}</router-link>
            </template>
            <template v-else>
              <router-link :to="`/${product.seo_url}`">{{ product.name }}</router-link>
            </template>
            <br />
          </div>
          <template v-if="isLoggedIn">
            <p>
              <small><i>{{ product.farm_name }}</i></small>
            </p>
            <p>
              <small><span> {{ product.quantity }}&nbsp; </span>
                <template v-if="product.unit != 'each'">
                  {{ product.quantity > 1 ? product.plural : product.unit }}
                  {{
                  product.unit_quantity > 1
                  ? " of " + product.unit_quantity
                  : ""
                  }}
                </template>
              </small>
            </p>
            <p><small>&nbsp;</small></p>
            <p>
              <b>${{ $filters.numberFormat(product.price_per_unit) }} / {{ product.unit }}</b>
              <Popper v-if="product.discount" :arrow="true" :hover="true" :content="`${product.discount * 100}% Off`">
                <div class="ms-2 badge bg-peach">
                  <span>{{ product.discount * 100 }}% Off</span>
                </div>
              </Popper>
            </p>
            <p></p>
            <br />
          </template>
        </div>
        <div class="d-flex align-items-center justify-content-center my-2">
          <a v-if="!isLoggedIn" :href="'login'" class="btn btn-theme ml-1">Login</a>
          <template v-else>
            <add-to-cart :max="product.quantity" :prod="product" :min="product.min_qty" :deliveryDate="deliveryDate" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { misc } from "@/mixins";
import AddToCart from "./AddToCart.vue";
import AddToWishlist from "./AddToWishlist.vue";
import Popper from "vue3-popper";
export default {
  name: "ProductCardHG",
  props: {
    max: {
      type: Number,
      default: 1,
    },
    product: {
      type: Object,
      required: true,
    },
    deliveryDate: {
      required: true,
    },
  },
  components: {
    AddToCart,
    AddToWishlist,
    Popper,
  },
  mixins: [misc],
};
</script>

<style scoped>
#view-grid .product-wrapper {
  overflow: inherit !important;
}
.card {
  border: 1px solid #000;
  padding: 5px;
  margin-right: 10px;
}
.no-overflow {
  overflow: initial;
}
.no-overflow .product-wrapper,
.no-overflow .product-wrapper .product-img {
  overflow: initial !important;
}
.product-wrapper .product-img {
  overflow: initial !important;
}
@media screen and (max-width: 480px) {
  .product-name a {
    text-decoration: none;
    font-size: 12px;
  }
  .product-name {
    height: 70px !important;
  }
}
.breakdown-popper .popper #arrow {
  display: none;
}
.breakdown-popper .popper {
  padding: 0px;
  background: #5c5c5c !important;
  border-radius: 30px;
  color: #fff;
}
.breakdown-popper tr {
  border: 0 !important;
}
.breakdown-popper td,
.breakdown-popper th {
  border: 0 !important;
  padding: 2px !important;
  vertical-align: top;
  color: inherit !important;
}
.breakdown-popper tr > td:first-child,
.breakdown-popper tr > th:first-child {
  padding-left: 30px !important;
}
.breakdown-popper tr > td:last-child,
.breakdown-popper tr > th:last-child {
  padding-right: 30px !important;
  text-align: right;
}
.breakdown-popper thead td {
  padding: 10px !important;
  border-bottom: 1px solid #fff !important;
  font-weight: 600 !important;
  text-align: center !important;
}
.breakdown-popper tbody tr td:first-child {
  padding-right: 25px !important;
}
.breakdown-popper tbody tr:first-child td {
  padding-top: 15px !important;
}
.breakdown-popper tbody tr:last-child td {
  padding-bottom: 15px !important;
}
.breakdown-popper tbody tr.popper-content-header {
  font-size: 1.3em;
  font-weight: 600;
  text-transform: uppercase;
}
</style>
<template>
  <button
    @click="addToQuote"
    class="btn btn-theme"
  >
  <template v-if="btnTxt">
    {{ btnTxt}}
  </template>
  <template v-else>
    Quote
  </template>
  </button>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "MoveToQuote",
  props: {
    quant: {
      type: Number,
      default: 1,
    },
    wishlist: {
      type: Number,
      required: true,
    },
    btnTxt: {
      type: String,
      required: false,
      default: ''
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  methods: {
    
    addToQuote() {  
      this.emitter.emit("showLoader");
      let addDone;
      if (this.isLoggedIn) {
        addDone = axios.post(
          `${process.env.VUE_APP_API_BASE}auth/wishlistToQuote`,
          {
            wishlist_id:this.wishlist
          }, // the data to post
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
      } else {
        addDone = Promise.resolve({
          data: {
            error: false,
            message: "Success: You have added to your quote list",
          },
        });
      }

      addDone.then(({ data }) => {
        if (data.error == true) {
          this.emitter.emit("a2q", {
            status: 0,
            msg: data.message,
            level: "error",
          });
        } else {
          this.emitter.emit("a2q", {
            status: 1,
            msg: data.message,
            level: "success",
          });
        }
      });
      this.emitter.emit("hideLoader");
    },
  },
};
</script>
<style scoped>
.btn-theme {
  padding: 8px 20px;
  background: transparent;
  border: 2px solid #f37f62;
  border-radius: 0px;
  letter-spacing: 4px;
  color: #f37f62;
  font-weight: 600;
  transition: 300ms all;
  line-height: 18px;
  font-size: 14px;
  font-family: "Raleway", sans-serif;
}
</style>
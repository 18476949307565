<script>
import { useHead } from '@unhead/vue';
import { watch } from "vue";
import { onBeforeRouteUpdate, useRoute } from "vue-router";

export default {
    name: "PageCanonical",
    props: {
        canonical: {
            type: Object,
            default() {
                return {
                totalRecords: 0,
                };
            },
        },
    },
    setup(props) {
        const route = useRoute();
        var metaInformation = {};
        if (props.canonical.totalRecords) {
            const calcCanonical = (routeObj) => {
                const links = [{ 
                    rel: "canonical", 
                    href: window.location.origin + routeObj.fullPath,
                    key: "canonical"
                }];

                const currPage = parseInt(routeObj.query.pageNumb, 10);
                if (currPage > 1) {
                    const prevPath = window.location.origin + routeObj.fullPath.replace(
                        `pageNumb=${currPage}`,
                        `pageNumb=${currPage - 1}`
                    );
                    links.push({
                        rel: "prev", 
                        href: prevPath,
                        key: "prev"
                    });
                } else {
                    links.push({
                        rel: "prev", 
                        href: "",
                        key: "prev"
                    });   
                }

                if (props.canonical.totalRecords) {
                    const lastPage = Math.ceil(
                        props.canonical.totalRecords / routeObj.query.perPage
                    );
                    if (currPage < lastPage) {
                        const nextPath = window.location.origin + routeObj.fullPath.replace(
                            `pageNumb=${currPage}`,
                            `pageNumb=${currPage + 1}`
                        );
                        links.push({
                            rel: "next", 
                            href: nextPath,
                            key: "next"
                        });
                    } else {
                        if (currPage == lastPage) {
                            links.push({
                                rel: "next",
                                href: "",
                                key: "next"
                            });
                        }
                    }
                }

                return links;
            };

            metaInformation.link = calcCanonical(route);

            onBeforeRouteUpdate((to) => {
                metaInformation.link = calcCanonical(to);
                useHead(metaInformation);
            });

            watch(
                () => props.canonical.totalRecords,
                () => {
                    metaInformation.link = calcCanonical(route);
                    useHead(metaInformation);
                }
            );
        }

        useHead(metaInformation);
    },
};
</script>  
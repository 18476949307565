<template>
  <div
    class=" align-items-center text-center text-lg-start "
    v-if="isLoggedIn && eligibleProgs.length"
  >
    <h5 class=" col-lg-12 cutoff_label pt-2 pt-lg-0" @click="toggleCutoff()">
      CUTOFFS
      <span v-if="displayCutoff">
        <i class="fa-solid fa-caret-down" style="color: #f48062"></i>
      </span>
      <span v-else>
        <i class="fa-solid fa-caret-right" style="color: #f48062"></i
      ></span>
    </h5>

    
      <div class="col-lg-12 " v-show="displayCutoff" role="alert">
        <table class="table w-90 table-borderless">
          <thead>
            <tr>
              <th scope="col" v-if="!specificProg">Program</th>
              <th scope="col" v-if="isMMT">Origin</th>
              <th scope="col" v-if="isDDB || isHOL">Vendor</th>
              <th scope="col">Carrier</th>
              <th scope="col">Date</th>
              <th scope="col">Time</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(row, i) in cutOffs" :key="i">
              <tr v-if="eligibleProgs.includes(row.programId)">
                <td v-if="!specificProg" class="text-orange">
                  <b>{{ row.programName }}</b>
                </td>
                <td v-if="isMMT" class="text-orange">
                  <b>{{ row.origin }}</b>
                </td>
                <td v-if="isDDB || isHOL" class="text-orange">
                  <b>{{ row.farmName }}</b>
                </td>
                <td>{{ row.shippingMethodName }}</td>
                <td>{{ row.shippingDate }}</td>
                <td>
                  {{ row.cutoff }} 
                  <template v-if="row.programTimezone == null">
                    {{ row.branchTimezone }}
                  </template>
                  <template v-else>
                    {{ row.programTimezone }}
                  </template>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CutOffs",
  props: {
    specificProg: {
      type: Boolean,
      default: false,
    },
    isMMT: {
      type: Boolean,
      default: false,
    },
    isDDB: {
      type: Boolean,
      default: false,
    },
    isHOL: {
      type: Boolean,
      default: false,
    },
    cutOffs: {
      type: Object,
      required: true,
    },
    eligibleProgs: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      displayCutoff: false,
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  methods: {
    toggleCutoff() {
      this.displayCutoff = !this.displayCutoff;
    },
  },
};
</script>
<style scoped>
h5.cutoff_label {
  font-size: 16px;
  font-weight: bold;
  color: #f48062;
  cursor: pointer;
}
.w-90 {
  width: 100% !important;
  border: 1px solid #ccc;
}
.w-90 th {
  color: #000;
  background-color: #ccc;
}
.text-orange {
  color: #f48062 !important;
}
</style>
<template>
  <!-- Modal -->

  <div class="modal fade show" style="display: block" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" :class="position == 'center' ? 'modal-dialog-centered' : ''">
      <div class="modal-content">
        <div class="modal-header" v-if="$slots.header">
          <h5 class="modal-title" id="exampleModalLabel">
            <slot name="header"></slot>
          </h5>
          <button type="button" class="btn-close" @click="closeModal" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div v-if="htmlContent" class="modal-body m-0 p-0" v-html="htmlContent"></div>
        <div class="modal-body m-0 p-2">
          <input type="text" v-model="newEmail" @keyup="validateEmail" placeholder="E-mail Address" class="form-control d-block w-100 mb-2 p-2 text-center" />
          <div class="text-danger text-center" v-if="displayErrorMessage == 1">Please enter a valid email address</div>
        </div>
        <div class="modal-body text-center">
          <slot></slot>
        </div>
        <div class="modal-footer">
          <input
            type="submit"
            @click="submitModal"
            class="btn btn-primary"
            :value="submitBtn"
            v-if="submitBtn"
          />
          <input
            type="submit"
            @click="closeModal"
            class="btn btn-primary"
            :value="closeBtn"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="modal-backdrop fade show"></div>
</template>

<script>
export default {
  name: "ModalForm",
  props: {
    closeFn: {
      type: Function,
      required: true,
    },
    closeBtn: {
      type: String,
      default: "OK",
    },
    submitFn: {
      type: Function,
    },
    submitBtn: {
      type: String,
      default: "",
    },
    htmlContent: {
      default: "",
    },
    position: {
      default: "",
    },
    emailAddress: {
      type: String,
      default: '',
      required: true
    }
  },
  data() {
    return {
      newEmail: this.emailAddress.trim(),
      displayErrorMessage: this.emailAddress.trim() !='' ? 0 : 1,
    };
  },
  methods: {
    closeModal() {
      this.closeFn();
    },
    submitModal() {
      if (this.newEmail.trim() != '' && this.displayErrorMessage == 0) {
        this.submitFn(this.newEmail);
      }
    },
    validateEmail() {
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.newEmail = String(this.newEmail).toLocaleLowerCase();
      if (!emailRegex.test(this.newEmail)) {
        this.displayErrorMessage = 1;
      } else {
        this.displayErrorMessage = 0;
      }
    },
  },
};
</script>
<style scoped>
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #9f9f9f;
  border-color: #9f9f9f;
}

.buttons .btn:hover,
.btn[type="submit"]:hover,
#account-login .well .btn:hover {
  border: 2px solid #9f9f9f;
  background: #9f9f9f;
  color: #fff;
}
.buttons .btn,
.btn[type="submit"],
#account-login .well .btn {
  border: 2px solid #9f9f9f;
  border-radius: inherit;
  background: inherit;
  color: #797979;
  padding: 8px 25px;
}
.btn-primary:hover {
  color: #fff;
  background-color: #9f9f9f;
  border-color: #9f9f9f;
}
h4 {
  font-size: 1.2 rem;
}
thead {
  background: #efefef;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.modal-footer {
  justify-content: center !important;
}

.btn[type="submit"] {
  border-radius: 0px;
}
</style>
<template>
    <a @click="scrollTop" v-show="visible" href="#" id="scroll"><span class="text-white"><i class="fa-solid fa-arrow-up"></i></span></a>
</template>
<script>
export default {
    name: "ScrollTop",
    data() {
        return {
            visible: false
        }
    },
    methods: {
        scrollTop: function () {
            window.scroll(0, window.scrollY - 50)
        },
        scrollListener: function () {
            this.visible = window.scrollY > 600
        }
    },
    mounted: function () {
        window.addEventListener('scroll', this.scrollListener)
    },
}
</script>
<style scoped>
#scroll {
    position: fixed;
    bottom: 90px;
    right: 22px;
    z-index: 99;
    font-size: 18px;
    border: none;
    border-radius: 50%;
    outline: none;
    color: white;
    cursor: pointer;
    padding: 15px;
    border: 1px solid #fff;
}
</style>
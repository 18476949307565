<template>
  <div class="col-lg-3 mb-4 col-6 product-grid no-overflow no-overflow">
    <div class="product-wrapper text-center">
      <div class="product-img position-relative">
        <add-to-wishlist
          v-if="isLoggedIn"
          :prod="product"
          class="fr-wish"
        ></add-to-wishlist>
        <router-link :to="`/${product.seo_url}`">
          <div class="ribbon-flower-library" v-if="product.display_ribbon">
            <span>{{ product.ribbon_name }}</span>
          </div>
          <img
            v-if="product.image"
            :src="`${imagePath}` + product.image"
            class=""
            :alt="product.name"
          />
          <img
            v-else
            :src="`${imagePath}` + getDefaultImage(product.product_type_id)"
            class=""
            :alt="product.name"
          />
        </router-link>
      </div>
      <div class="px-3 pb-3">
        <div class="product-details">
          <div class="product-name mb-1">
            <router-link class="quote_p_name" :to="`/${product.seo_url}`">
              {{ product.name }}</router-link
            ><br />
          </div>
          <div class="unit_name mb-1">
            <span v-if="product.unit_id"> {{ product.unit }}</span>
            <select
              v-else
              @change="setUnitType($event, product)"
              class="
                form-select
                product_unit
                d-inline-block
                w-auto
                bg-transparent
              "
            >
              <option
                v-for="(fllUnitType, index) in fllUnitTypes"
                :key="index"
                :value="fllUnitType.id"
                :selected="fllUnitType.id == fllUnitTypes[0].id"
              >
                {{ fllUnitType.unit }}
              </option>
            </select>
            <br />
          </div>
        </div>
        <div class="d-flex align-items-center justify-content-center my-2">
          <div v-if="!this.product.coming_soon" class="quantity">
            <input
              type="number"
              :min="quantityIncrement"
              :max="maxQuoteQuantity"
              :step="quantityIncrement"
              id="qty6"
              v-model="desiredQuant"
              class="form-control"
            />
          </div>
          <add-to-quote :quant="desiredQuant" :prods="[product]" :comingSoon="product.coming_soon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { misc } from "@/mixins";
import AddToQuote from "./AddToQuote.vue";
import AddToWishlist from "./AddToWishlist.vue";
export default {
  name: "ProductCardQuote",
  props: {
    max: {
      type: Number,
      default: 1,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  components: {
    AddToQuote,
    AddToWishlist,
  },
  mixins: [misc],
  data() {
    return {
      desiredQuant: this.product.qty,
      quantityIncrement: this.product.increment_quantity,
      maxQuoteQuantity: this.maxQuoteQuantity,
      product_unit_id: this.product.unit_id,
      fllUnitTypes: JSON.parse(localStorage.getItem("fllUnitTypes")),
    };
  },
  method: {
    setUnitType(event, prod) {
      prod.unit_id_dd = parseInt(event.target.value, 10);
    },
  },
};
</script>

<style scoped>
a.quote_p_name {
  text-decoration: none;
}
.card {
  border: 1px solid #000;
  padding: 5px;
  margin-right: 10px;
}
.unit_name {
  text-transform: capitalize;
  height: 38px;
}

select {
  text-transform: capitalize;
}
option {
  text-transform: capitalize;
  padding: 10px;
  line-height: 15px;
}
.no-overflow {
  overflow: initial;
}
.no-overflow .product-wrapper,
.no-overflow .product-wrapper .product-img {
  overflow: initial !important;
}
</style>
<template>
  <div v-if="pagesCount > 1">
    <ul class="pagination">
      <template v-if="currPage !== 1">
        <li class="page-item" @click.prevent="changePage(1)">
          <a class="page-link" href="JavaScript:Void(0);"><span>|&lt;</span></a>
        </li>
        <li class="page-item" @click.prevent="changePage(currPage - 1)">
          <a class="page-link" href="JavaScript:Void(0);"><span>&lt; </span></a>
        </li>
      </template>
      <li
        v-for="n in maxLinkBoxx"
        :class="[{ active: startPage + n - 1 === currPage }, 'page-item']"
        :key="n"
        @click.prevent="changePage(startPage + n - 1)"
      >
        <a class="page-link" href="JavaScript:Void(0);"
          ><span>{{ startPage + n - 1 }}</span></a
        >
      </li>

      <template v-if="currPage !== pagesCount">
        <li class="page-item" @click.prevent="changePage(currPage + 1)">
          <a class="page-link" href="JavaScript:Void(0);"><span>></span></a>
        </li>

        <li class="page-item" @click.prevent="changePage(pagesCount)">
          <a class="page-link" href="JavaScript:Void(0);"><span>>|</span></a>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PaginationRow",
  props: {
    recordsCount: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    currPage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      maxLinkBox: 9,
      maxBoxLeftSide: 4,
    };
  },
  computed: {
    pagesCount() {
      return Math.ceil(this.recordsCount / this.perPage);
    },
    maxLinkBoxx() {
      return Math.min(this.pagesCount, this.maxLinkBox);
    },
    startPage() {
      let startPage = this.currPage - this.maxBoxLeftSide;

      if (startPage < 1) {
        return 1;
      }

      const endPage = startPage - 1 + this.maxLinkBoxx;
      if (endPage > this.pagesCount) {
        startPage = startPage - (endPage - this.pagesCount);
      }

      if (startPage < 1) {
        return 1;
      }

      return startPage;
    },
  },
  methods: {
    changePage(pageNumb) {
      var element = document.getElementsByClassName("pagination")[0];
      var headerOffset = 100;
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
      });
      
      this.$emit("pageChange", pageNumb);
    },
  },
};
</script>
<style scoped>
ul.pagination {
  float: right;
}
</style>

<template>
  <div class="col-lg-3 mb-4 col-6 product-grid no-overflow">
    <div class="product-wrapper text-center">
      <div class="product-img px-4 py-3 position-relative">
        <add-to-wishlist
          v-if="isLoggedIn"
          :prod="product"
          class="fr-wish"
        ></add-to-wishlist>
        <div class="ribbon" v-if="product.highlight_name && isLoggedIn">
          <span :style="`border-color: ${product.highlight_hex_code}; background: ${product.highlight_hex_code}; background: linear-gradient(${ product.highlight_hex_code } 0%, ${ product.highlight_hex_code } 100%);`">{{ product.highlight_name }}</span>
        </div>
        <img v-if="product.image" :src="product.image" class="" :alt="product.product_name" />
        <img v-else :src="`${imagePath}` + getDefaultImage(product.product_type_id)" class="" :alt="product.product_name" />
      </div>
      <div class="px-3 pb-3">
        <div class="product-details">
          <div class="product-name  mb-1">
             <Popper class="d-flex breakdown-popper mr-2 d-inline-flex"
                v-if="
                  product.breakdown != null &&
                  product.breakdown.length > 2
                "
                :arrow=true
                :hover=true
              >
                <template #content
                  ><div
                    v-html="
                      getBreakdown(
                        product.breakdown,
                        product.box_unit_singular
                      )
                    "
                  ></div>
                </template>
                <span class="star-color"
                  ><i class="fa-solid fa-star text-warning" aria-hidden="true"></i
                ></span>
              </Popper> 
              <span class="d-inline-block highlight-description" v-if="product.highlight_description">
                <Popper :arrow="true" :hover="true" :content="product.highlight_description" style="margin:0; border: none;">
                  <i class="fa-solid fa-circle-info info-icon"></i>
                </Popper>
              </span>
              {{ product.name }}
              <br />
            <Popper
                v-if="product.country_id > 0" 
                :arrow=true
                :hover=true
                :content="product.country_label"
                >
                  <div class="text-center is_us_container" ><span  class="us_img_icon"> <img :src="`${imagePath}` +
                  product.country_flag"/></span> </div>
              </Popper>
          </div>
          <template v-if="isLoggedIn">
            <p>
              <small
                ><i>{{ product.mmt_origin_name }}</i></small
              >
            </p>
            <p>
              <small>
                {{ product.box_name_singular }}

                <span>({{ product.unit_count }}
                  {{
                    product.unit_count > 1
                      ? product.box_unit_plural
                      : product.box_unit_singular
                  }})
                </span>
              </small>
            </p>
            <p><small>&nbsp;</small></p>
            <p>
              <b>${{ $filters.numberFormat(product.price_per_stem) }} / {{ product.box_unit_singular }}</b>
              <Popper v-if="product.special" :arrow="true" :hover="true" :content="`${product.special}% Off`">
                <div class="ms-2 badge bg-peach">
                  <span>{{ product.special }}% Off</span>
                </div>
              </Popper>
            </p>
            <p>
              <small
                v-if="product.box_name_singular != product.box_unit_singular"
              >
                ${{ $filters.numberFormat(product.price_per_unit) }} /
                {{ product.box_name_singular }}
              </small>
              <small v-else>&nbsp;</small>
            </p>
          </template>
        </div>
        <div class="d-flex align-items-center justify-content-center my-2">
          <a
            v-if="!isLoggedIn"
            :href="'/login'"
            class="btn btn-theme ml-1"
            >Login</a
          >
          <template v-else>
            <add-to-cart
              :prod="product"
              :min="product.min_qty"
              :step="product.inc_qty"
              :deliveryDate="deliveryDate"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { misc } from "@/mixins";
import AddToCart from "./AddToCart.vue";
import AddToWishlist from "./AddToWishlist.vue";
import Popper from "vue3-popper";
export default {
  name: "ProductCardMMT",
  props: {
    max: {
      type: Number,
      default: 1,
    },
    product: {
      type: Object,
      required: true,
    },
    deliveryDate: {
      required: true,
    },
  },
  components: {
    AddToCart,
    AddToWishlist,
    Popper
  },
  mixins: [misc],
  data() {
    return {
      us_message: 'US Product'
    }
  },
  mounted() {
    let site_settings = JSON.parse(localStorage.getItem('site_settings'));
    this.us_message = site_settings.us_flag_message
  }
};
</script>

<style scoped>
.card {
  border: 1px solid #000;
  padding: 5px;
  margin-right: 10px;
}
.no-overflow {overflow: initial;}
.no-overflow .product-wrapper,.no-overflow .product-wrapper  .product-img{ overflow: initial !important;}
.us_img_icon {width:40px;}
/* .us_img_icon img{width:40px; height: 20px;} */
.is_us_container {width: 40px; margin: 0px auto;}
.star-color {
  color: #f37f62;
}
.product-name a {text-decoration: none;}
</style>
<style>
.tbl_breakdown {    
  color: #fff;
  font-size: 12px;
  font-weight: normal;

}

.text-center .tbl_breakdown th, .text-center .tbl_breakdown th{    
  text-align-last: initial;
  padding-left:5px;
}

.text-left {
  text-align-last: initial !important;
  padding-left: 5px;
}
.text-left td {
  padding-left: 5px !important;
}
@media screen and (max-width : 480px) {
	.product-name {
    text-decoration: none;
    font-size: 12px;
    height: 70px !important;
  }
}
</style>
<template>
  <template v-if="displayPopup">
    <div class="modal fade show d-block" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <button type="button" class="btn-close" @click="closeModal" data-bs-dismiss="modal" aria-label="Close"></button>
          <div class="modal-body m-0 p-0" v-html="htmlContent"></div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </template>
</template>
<script>
export default {
  name: "PopUp",
  props: {
    closeFn: {
      type: Function,
      required: true,
    },
    htmlContent: {
      default: "",
    }
  },
  data() {
    return {
      displayPopup: false,
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.$route.name != 'Carts' && this.$route.name != 'Checkout') {
        this.displayPopup = true;
      }
    }, 1000)
  },
  methods: {
    closeModal() {
      this.closeFn();
    },
  },
};
</script>
<style scoped>
  .btn-close {
    z-index: 1;
    position: absolute;
    top: 5px;
    right: 5px;
  }
</style>
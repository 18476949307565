<template>
  <div class="date-picker-container">
    <div class="input-wrapper input-group flex-nowrap" ref="triggerRef" @click="toggleDatePicker">
      <input v-model="selectedDate" class="form-control" readonly placeholder="Select date">
      <span class="input-group-text"><i class="fas fa-calendar"></i></span>
    </div>
    
    <div v-show="isOpen" ref="datePickerRef" class="date-picker-wrapper">
      <div ref="arrow" class="popover-arrow"></div>
      <v-date-picker
        @update:modelValue="handleDateSelect"
        :key="key"
        v-model="dateModel"
        :first-day-of-week="1"
        color="green"
        :masks="{ input: 'MM-DD-YYYY', data: 'YYYY-MM-DD' }"
        trim-weeks
        :available-dates="availableDates"
        :min-date="minDate"
        :max-date="maxDate"
        :disabled-dates="disabledDates"
        :timezone="timezone"
        :minute-increment="minuteIncrement" 
        @dayclick="
          (_, event) => {
            event.target.blur();
          }
        " 
      />
    </div>
  </div>
</template>

<script>
import 'v-calendar/dist/style.css';
import { DatePicker as VDatePicker } from 'v-calendar';
import { misc } from "@/mixins";
import { Dater } from "@/classes";
import { createPopper } from '@popperjs/core'

export default {
  name: "DatePicker",
  emits: ["update:dateProp", "pickerDateChanged"],
  components: { VDatePicker },
  mixins: [misc],
  props: {
    availableDates: {
      type: [Array, Object],
      default() {
        return [];
      },
    },
    minDate: {
      type: String,
      default: "",
    },
    maxDate: {
      type: String,
      default: "",
    },
    disabledDates: {
      type: Array,
      default() {
        return [];
      },
    },
    dateProp: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },

    is_required: {
      type: Boolean,
      default: false,
    },
    mode: {
      default: "date", //date, time, datetime
      type: String,
    },
    timezone: {
      type: String,
      default: undefined,
    },
    minuteIncrement: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      key: 0,
      selectedDate: null,
      isOpen: false,
      popperInstance: null,
    };
  },
  computed: {
    dateModel: {
      get: function () {
        return this.appendTimeForBrowser(this.dateProp);
      },
      set: function (n) {
        if (n) {
          if (typeof n.getMonth === "function") {
            if (this.mode === "date") {
              n = this.formatToDb(n);
            } else if (this.mode === "datetime") {
              n = Dater.toDbDateTime(n);
            }
          }

          this.$emit("update:dateProp", n);

          this.processDateChange();
        } else {
          this.key++;
        }
      },
    },
  },
  methods: {
    processDateChange() {
      this.$emit("pickerDateChanged", this.dateModel);
    },
    handleClickOutside(event) {
      if (
        this.isOpen &&
        !this.$refs.datePickerRef.contains(event.target) &&
        !this.$refs.triggerRef.contains(event.target)
      ) {
        this.isOpen = false
      }
    },
    toggleDatePicker() {
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        this.$nextTick(() => {
          this.initializePopper()
        })
      }
    },
    handleDateSelect(date) {
      this.selectedDate = date ? this.formatDateWithPaddedZeros(date) : '';
      this.isOpen = false
    },
    initializePopper() {
      this.popperInstance = createPopper(
        this.$refs.triggerRef,
        this.$refs.datePickerRef,
        {
          placement: 'bottom-start',
          modifiers: [
            {
              name: "arrow",
              options: {
                element: this.$refs.arrow,
              },
            },
            {
              name: 'offset',
              options: {
                offset: [0, 5]
              }
            },
            {
              name: 'flip',
              options: {
                fallbackPlacements: ['top-start']
              }
            }
          ]
        }
      )
    },
  },
  mounted() {
    this.handleDateSelect(this.dateModel);
    document.addEventListener('click', this.handleClickOutside)
  },
  unmounted() {
    document.removeEventListener('click', this.handleClickOutside)
    if (this.popperInstance) {
      this.popperInstance.destroy()
    }
  }
};
</script>

<style scoped>
.form-control {
  border-width: 1px;
}
.date-picker-container {
  position: relative;
}

.input-wrapper {
  position: relative;
  cursor: pointer;
}

.input-wrapper input {
  padding-right: 30px;
  cursor: pointer;
}

.input-wrapper i {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.date-picker-wrapper {
  z-index: 1000;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.375rem;
}

.form-input-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px;
  max-width: 300px;
  position: relative;
}

.form-input {
  flex: 1;
  border: none;
  outline: none;
  padding: 4px;
}

.popover-arrow {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #fff;
  transform: translateX(-50%) rotate(45deg) !important;
}

[data-popper-placement^="top"] > .popover-arrow {
  bottom: -5px;
  border-right: 1px solid #cbd5e1;
  border-bottom: 1px solid #cbd5e1;
  border-top: 0px solid #fff;
  background: #fff !important;
  z-index: 999999;
  transform: translateX(20px) rotate(45deg) !important;
}

[data-popper-placement^="bottom"] > .popover-arrow {
  top: -5px;
  border-left: 1px solid #cbd5e1;
  border-top: 1px solid #cbd5e1;
  border-bottom: 0px solid #fff;
  background: #fff !important;
  z-index: 999999;
  transform: translateX(20px) rotate(45deg) !important;
}
</style>
<template>
  <div :class="`alert alert-${alertClass}`" v-if="show" ref="alert">
    <a
      href="#"
      class="close"
      @click="closeAlert"
      data-dismiss="alert"
      aria-label="close"
      >&times;</a
    >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "FlashAlert",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    level: {
      type: String,
      default: "error", // success, error
    },
  },
  computed: {
    alertClass() {
      if (this.level === "error") {
        return "danger";
      } else if (this.level === "success") {
        return "success";
      }
      return "";
    },
  },
  updated() {
    this.$nextTick(function () {
      if (this.show && this.level === 'error') {
        const el = this.$refs.alert;
        if (el) {
          window.scrollTo(0, el.offsetTop - el.offsetHeight - 50);
        }
      }
    });
  },
  methods: {
    closeAlert() {
      this.$emit("update:show", false);
    },
  },
};
</script>
<template>
  <div class="quantity d-flex m-auto">
    <input
      type="number"
      :min="isLoggedIn ? product.quantityIncrement : product.min_increment"
      :max="maxQuoteQuantity"
      :step="isLoggedIn ? product.quantityIncrement : product.min_increment"
      v-model.number="quantity"
      class="form-control width-72 mr-10"
    />
    <Tooltips text="Update Quantity">
      <button @click="updateQuote" class="btn btn-primary ml-10">
        <i class="fa-solid fa-arrows-rotate arrows-rotate-icon" aria-hidden="true"></i>
      </button>
    </Tooltips>
  </div>
</template>

<script>
import axios from "axios";
import Tooltips from "./Tooltips.vue";
import { mapMutations } from "vuex";
import { mapGetters } from "vuex";
export default {
  name: "UpdateQuote",
  components: {
    Tooltips,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    prodId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      quantity: this.product.quant,
      currentQuantity: this.product.quant,
      maxQuoteQuantity: JSON.parse(localStorage.getItem("site_settings")).max_quote_quantity,
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  methods: {
    ...mapMutations(["updateQuoteQuant"]),

    updateQuote() {
      if (!this.quantity) {
        this.emitter.emit("updateQuote", {
          status: 0,
          msg: "Please input the quantity.",
          level: "error",
        });
        return;
      }

      let quantityIncrement = 0;
      if (this.isLoggedIn) {
        quantityIncrement = this.product.quantityIncrement;
      } else {
        quantityIncrement = this.product.min_increment;
      }

      if (this.quantity < quantityIncrement || this.quantity % quantityIncrement > 0) {
        this.emitter.emit("updateQuote", {
          status: 0,
          msg: "Quantities need to be in groups of " + this.product.min_increment + ". Please try again.",
          level: "error",
        });
        return;
      }

      this.emitter.emit("showLoader");
      if (this.isLoggedIn) {
        axios
        .post(
          `${process.env.VUE_APP_API_BASE}auth/updateQuote`,
          {
            product_id: this.prodId,
            unit_id: this.product.unitId,
            quantity: this.quantity,
          }, // the data to post
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then(({ data }) => {
          if (data.error == true) {
            this.emitter.emit("updateQuote", {
              status: 0,
              msg: data.message,
              level: "error",
            });
          } else {
            this.currentQuantity = this.quantity;
            this.updateQuoteQuant({
              quant: this.quantity,
              prodId: this.prodId,
            });
            this.updateQuoteGAnalytics();
            this.emitter.emit("updateQuote", {
              status: 0,
              msg: "Quote updated successfully.",
              level: "success",
            });
          }
          this.emitter.emit("hideLoader");
        });
       } 
      else {
        this.currentQuantity = this.quantity;
        this.updateQuoteQuant({
          quant: this.quantity,
          prodId: this.prodId,
        });
        this.updateQuoteGAnalytics();
        this.emitter.emit("updateQuote", {
          status: 0,
          msg: "Quote updated successfully.",
          level: "success",
        });
        this.emitter.emit("hideLoader");
      }
    },

    updateQuoteGAnalytics() {
      let productObj = {
        event_category: "quote",
        event_label: 'update quote',
        item_id: this.product.prodId,
        item_name: this.product.prodName,
        item_category: this.product.prodCategoryName,
        item_category2: this.product.prodVarietyName,
        item_variant: this.product.prodColorName,
        item_category3: this.product.prodGradeName,
        quantity: parseInt(this.quantity),
      };
      this.$gtag.event('update_quote', productObj);
    }
  },
};
</script>
<style scoped>
.width-72 {
  width: 72px !important;
}
.ml-10 {
  margin-left: 10px !important;
  padding-top: 8px;
  text-transform: capitalize;
}
.f-12 {
  font-size: 12px;
  margin-bottom: 0px;
}
.mt-8 {
  margin-top: 8px;
}
</style>
<template>
  <div class="quantity-details d-flex align-items-center justify-content-center justify-content-md-center">
    <input v-if="product.program_type == 'PRB' || product.program_type == 'DBL' || product.program_type == 'DBL2' || product.program_type == 'MMT' || (product.program_type == 'SBL' && product.unlimited_quantity == 1)"
      type="number"
      :min="product.min_quantity"
      :step="product.inc_quantity"
      v-model.number="quantity"
      class="form-control mb-2 mb-md-0 mr-10"
    />
    <input v-else-if="product.program_type != 'MDB'"
      type="number"
      :min="product.min_quantity"
      :step="product.inc_quantity"
      :max="product.max_quantity"
      v-model.number="quantity"
      class="form-control mb-2 mb-md-0 mr-10"
    />
    <!-- <div class="w-100 d-block d-md-none"></div>-->
    <Tooltips class="mt-2 mt-md-0 ms-md-2" text="Update Quantity" v-if="product.program_type != 'MDB'">
      <button @click="updateCart" class="btn btn-primary w-100">
        <i class="fa-solid fa-arrows-rotate arrows-rotate-icon" aria-hidden="true"></i>
      </button>
    </Tooltips>
    <div class="qty_uom ms-md-2" v-if="product.program_type == 'HGS'">
      <span class="d-block min_width_m_72">
        <template v-if="product.sold_by_singular != 'each'">{{ product.quantity > 1 ? product.sold_by_plural : product.sold_by_singular }}
          {{ product.unit_count > 1  ? ' of ' + product.unit_count : '' }}
        </template>
      </span>
    </div>
    <div class="qty_uom ms-md-2" v-if="product.program_type == 'LAL' || product.program_type == 'PRB' || product.program_type == 'WSP' || product.program_type == 'SBL'">
      {{ product.quantity > 1 ? product.sold_by_plural : product.sold_by_singular }}
      <span class="d-block" v-if="product.sold_by_singular != product.priced_by_singular"
        >({{ product.unit_count }} {{ product.unit_count > 1 ? product.priced_by_plural : product.priced_by_singular }})</span
      >
    </div>
    <div class="qty_uom ms-md-2" v-if="product.program_type == 'FDB' || product.program_type == 'DBL' || product.program_type == 'DBL2' || product.program_type == 'MMT'">
      {{ product.quantity > 1 ? product.box_plural : product.box_singular }} <br class="d-block d-md-none"/>({{ product.unit_count }} {{ product.unit_count > 1 ? product.box_unit_plural : product.box_unit_singular }})
    </div>
    <div class="qty_uom ms-md-2" v-if="product.program_type == 'MDB'">
      {{quantity}}
      {{ product.quantity > 1 ? product.box_plural : product.box_singular }} <br class="d-block d-md-none"/>({{ product.unit_count }} {{ product.unit_count > 1 ? product.box_unit_plural : product.box_unit_singular }})
    </div>
  </div>
  <p v-if="quantity > product.max_quantity && (product.program_type == 'LAL' || product.program_type == 'HGS' || product.program_type == 'FDB' || product.program_type == 'WSP' || (product.program_type == 'SBL' && product.unlimited_quantity == 0))" class="text-danger f-12">
    Only {{ product.max_quantity }} available. Please update your cart.
  </p> 
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";
import Tooltips from "./Tooltips.vue";
export default {
  name: "UpdateCart",
  components: {
    Tooltips
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      quantity: this.product.quantity,
      currentQuantity: this.product.quantity,
    };
  },
  methods: {
    ...mapActions(["fetchCartAndMeta"]),

    updateCart() {
      this.emitter.emit("showLoader");
      if (this.quantity == 0) {
        this.emitter.emit("cartUpdated", {
          status: 0,
          msg: "Please input quantity more than 0.",
          level: "error",
        });
        return;
      }

      if (this.quantity > this.max) {
        this.emitter.emit("cartUpdated", {
          status: 0,
          msg: "Product added into cart exceeds available quantity.",
          level: "error",
        });
        return;
      }

      if (this.quantity < this.product.min_quantity && this.product.program_type != 'MDB' && this.product.program_type != 'DBL' && this.product.program_type != 'DBL2') {
        this.emitter.emit("cartUpdated", {
          status: 0,
          msg: `You need to add at least ${this.product.min_quantity} products to meet the minimum requirements.`,
          level: "error",
        });
        return;
      }

      if (this.product.inc_quantity > 1 && this.product.program_type != 'MDB' && this.product.program_type != 'DBL' && this.product.program_type != 'DBL2') {
        let reminder = this.quantity % this.product.inc_quantity;
        if (reminder > 0) {
          this.emitter.emit("cartUpdated", {
            status: 0,
            msg: `Quantities need to be in groups of ${this.product.inc_quantity}. Please try again.`,
            level: "error",
          });
          return;
        }
      }

      axios
        .post(
          `${process.env.VUE_APP_API_BASE}auth/updateItem`,
          {
            cartItemId: this.product.cartItemId,
            quantity: this.quantity,
          }, // the data to post
          {
            headers: {
              "Content-type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then(({ data }) => {
          if (data.error == true) {
            this.emitter.emit("cartUpdated", {
              status: 0,
              msg: data.message,
              level: "error",
            });
            return;
          } else {
            this.currentQuantity = this.quantity;
            this.updateCartGAnalytics()
            this.fetchCartAndMeta();
            this.emitter.emit("cartUpdated", {
              status: 0,
              msg: "Cart updated successfully.",
              level: "success",
            });
            return;
          }
        });
    },

    updateCartGAnalytics() {
      // const totalPrice = this.product.total;
      let productObj = {
        event_category: "purchase",
        event_label: 'update cart',
        item_id: this.product.product_id,
        item_name: this.product.name,
        affiliation: this.product.farm_name,
        item_list_id: this.product.programtypeId,
        item_list_name: this.product.programtypeName,
        item_category: this.product.category_name,
        item_category2: this.product.variety_name,
        item_variant: this.product.color_name,
        item_category3: this.product.grade_name,
        quantity: parseInt(this.quantity),
        price: parseFloat(this.product.total),
        currency: "USD",
      };
      this.$gtag.event('update_cart', productObj);
    }
  },
};
</script>
<style scoped>
.f-12 {
  font-size: 12px;
  margin-bottom: 0px;
}
.tooltip-box{
  width:70px;
}
@media(min-width:992px){
  .flex_center {
    display: flex !important;
  }
}
@media(max-width:991px){
  .flex_center {
    text-align: center !important;
    justify-content: center !important;
  }
}
@media(max-width:767px){
  .qty_uom {
    flex: 1 1 0%;
    text-align: center;
  }
  .min_width_m_72 {
    min-width: 72px !important;
  }
}

</style>
<template>
    <div :class="containerClass" v-if="banners.length >= 1 && displayBanner"> 
        <div v-bind:class="{'container': !banner.full_width}" v-for="(banner, index) in banners" :key="index">
            <div v-html="banner.description"> </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
export default {
    name: "MyBanners",
    props: {
        position: {
            Type: String,
            required: true,
        },
    },
    data() {
        return {
            banners: [],
            containerClass: 'banner',
            displayBanner: false,
        };
    },
    mounted() {
        this.emitter.on("getTopBanners", () => {
            this.getBanners('top');
        });
        this.emitter.on("getBottomBanners", () => {
            this.getBanners('bottom');
        });
        if (this.position == 'top' || this.position == 'bottom') {
            this.getBanners();
            this.containerClass = this.position + '-' + this.containerClass;
        }
        setTimeout(() => {
            if (this.$route.name != 'Carts' && this.$route.name != 'Checkout') {
                this.displayBanner = true;
            }
        }, 1000)
    },
    methods: {
        getBanners(currentPosition = '') {
            let  apiUrl = process.env.VUE_APP_API_BASE + "auth/getBanners";
            axios
                .post(
                apiUrl,
                {
                    position: currentPosition != '' ? currentPosition : this.position,
                },
                {
                    headers: {
                    "Content-type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
                )
                .then((response) => {
                if (response.data.error === false)
                    this.banners = response.data.banners
                });
        }
    },
};
</script>

<template>
  <div class="deleteIcon">
    <div class="w-100 d-block d-md-none"></div>
    <Tooltips text="Delete">
      <button @click="showDeleteProdWarn(product)" class="btn px-3">
        <i class="fa-solid fa-trash-can" aria-hidden="true"></i>
      </button>
    </Tooltips>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import Tooltips from "./Tooltips.vue";
export default {
  name: "DeleteProduct",
  components: {
    Tooltips,
  },
  props: {
    itemId: {
      type: Number,
      default: 0,
    },
    product: {}
  },

  methods: {
    ...mapMutations(["showDeleteProdWarn"]),
  },
};
</script>
<template>
  <div class="tooltip-box">
    <span  data-bs-toggle="tooltip"  data-bs-trigger="click" data-bs-placement="auto" data-bs-container="body" :title="text" >
      <slot/>
    </span>
  </div>
  
</template>

<script>
export default {
  name: "ToolTips",
  props: { 
    text: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
.tooltip-box { 
  position: relative;
  display: inline-block;
}

.tooltip-box:hover .tooltip{
  opacity: 1;
}

.tooltip { 
  color: #ffffff;
  text-align: center;
  padding: 5px 0;
  border-radius: 2px;
  
  width: 120px;
  bottom: 100%;
  left: 50%;
  min-width:80px;
  margin-left: -60px;

  opacity: 0;
  transition: opacity 1s;

  position: absolute;
  z-index: 1;

  background: #000;
}

.text::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
}
</style>
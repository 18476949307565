<template>
    <div v-if="show" id="install-pwa-ios" class="w-100 text-center p-3">
      <button type="button" class="btn-close" @click="closeInstallIOS"></button>
      Install mayesh.com on your device: tab <img src="../assets/image/share-apple.png" />, then Add to Home Screen
    </div>
</template>
<script>
  import { mapGetters } from "vuex";
  import { pwa } from "@/mixins";
  export default {
    mixins: [pwa],
    data() {
      return {
        show: false,
        displayInstallIOS: 0,
      }
    },
    computed: {
        ...mapGetters(["isLoggedIn"]),
    },
    created() {
      this.displayInstallIOS = localStorage.getItem("display_install_ios");
      if (this.displayInstallIOS == '' || this.displayInstallIOS == undefined || this.displayInstallIOS == null) {
        this.displayInstallIOS = 1;
      }
      if (this.displayInstallIOS == 1 && this.isIOS() && !this.isInStandaloneMode() && this.isSafari() && !this.isOpera() && this.isLoggedIn && this.$route.name !== 'Carts' && this.$route.name !== 'Checkout') {
        this.show = true;
      }
    },
    methods: {
      closeInstallIOS() {
        this.show = false;
        localStorage.setItem("display_install_ios", 0);
      },
    }
  };
</script>
<style scoped>
  #install-pwa-ios {
    position: sticky;
    bottom: 10px;
    z-index: 999;
    background-color: #ebebea;
    color: black;
  }
  #install-pwa-ios:after {
    z-index: -1;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    content:'';
    width: 0;
    height: 0;
    border-top: solid 10px #ebebea;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
  }
  .btn-close {
    z-index: 1;
    position: absolute;
    top: 5px;
    right: 5px;
  }
</style>
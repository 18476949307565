<script>
import axios from "axios";
import { useHead } from '@unhead/vue';
import { useRoute } from "vue-router";

export default {
    name: "PageMetadata",
    props: {
        pageInformation: Object,
        canonical: {
            type: Object,
            default() {
                return {
                totalRecords: 0,
                };
            },
        },
    },
    setup(props) {
        const route = useRoute();
        var pageNoIndex = 0; 
        var metaInformation;
        var pageTitle = '';
        var pageDescription = '';
        
        if (props.pageInformation && props.pageInformation.title) {
            pageTitle = props.pageInformation.title; 
            pageDescription = props.pageInformation.meta_description ;
            pageNoIndex = props.pageInformation.no_index ? 1 : 0;
        } else {
            var start = route.path.lastIndexOf('/') + 1;
            var slug = route.path.substring(start);
            if (slug != '') {
                axios
                    .post(process.env.VUE_APP_API_BASE + 'auth/getPageSettings', {
                        slug: slug,
                        headers: {
                            'Content-type': 'application/json',
                            Authorization: 'Bearer ' + localStorage.getItem('token'),
                        },
                    })
                    .then(({ data }) => {
                        if (data.success) {
                            pageTitle = data.page.meta_title;
                            pageDescription = data.page.meta_description;
                            metaInformation = {
                                title: pageTitle,
                                titleTemplate: pageTitle + ' ' + localStorage.getItem("meta_title_brand"),
                                meta: [
                                    {
                                        name: "og:title",
                                        property: "og:title",
                                        content: pageTitle,
                                    },
                                    {
                                        property: "og:url",
                                        content: window.location.origin + route.fullPath
                                    },
                                    {
                                        property: "og:image",
                                        content: window.location.origin + '/mayesh.png'
                                    },
                                    {
                                        name: "twitter:title",
                                        property: "twitter:title",
                                        content: pageTitle,
                                    },
                                    {
                                        name: "twitter:url",
                                        property: "twitter:url",
                                        content: window.location.origin + route.fullPath
                                    },
                                    {
                                        name: "twitter:image",
                                        property: "twitter:image",
                                        content: window.location.origin + '/mayesh.png'
                                    },
                                    {
                                        name: "title",
                                        property: "title",
                                        content: pageTitle,
                                    },
                                    {
                                        itemprop: "name",
                                        content: pageTitle,
                                        key: "itemprop"
                                    },
                                    {
                                        name: "description",
                                        content: pageDescription,
                                    }
                                ],
                            };
                            if (data.page.no_index) {
                                metaInformation.meta.push({
                                    name: 'robots',
                                    property: 'robots',
                                    content: 'noindex,nofollow',
                                });
                            }
                        }
                        useHead(metaInformation);
                        return;
                    })
                    .catch((err) => {
                        console.log('An error occurred while retrieving the page settings. ', err);
                    });
            }
        }

        if (pageTitle) {
            metaInformation = {
                title: pageTitle,
                titleTemplate: pageTitle + (pageTitle != 'Mayesh' ? ' ' + localStorage.getItem("meta_title_brand") : ''),
                meta: [
                    {
                        name: "og:title",
                        property: "og:title",
                        content: pageTitle,
                    },
                    {
                        property: "og:url",
                        content: window.location.origin + route.fullPath
                    },
                    {
                        property: "og:image",
                        content: window.location.origin + '/mayesh.png'
                    },
                    {
                        name: "twitter:title",
                        property: "twitter:title",
                        content: pageTitle,
                    },
                    {
                        name: "twitter:url",
                        property: "twitter:url",
                        content: window.location.origin + route.fullPath
                    },
                    {
                        name: "twitter:image",
                        property: "twitter:image",
                        content: window.location.origin + '/mayesh.png'
                    },
                    {
                        name: "title",
                        property: "title",
                        content: pageTitle,
                    },
                    {
                        itemprop: "name",
                        content: pageTitle,
                        key: "itemprop"
                    },
                    {
                        name: "description",
                        content: pageDescription,
                    }
                ],
            };
        }
        if (pageNoIndex) {
            metaInformation.meta.push({
                name: 'robots',
                property: 'robots',
                content: 'noindex,nofollow',
            });
        }

        useHead(metaInformation);
    },
};
</script>  